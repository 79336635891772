//@ts-nocheck
//TODO: TS
import { AxiosPromise } from 'axios'
import axios from 'src/utils/API/axios'

export type TApi = (params?: object) => AxiosPromise<any>

export function request(method: string, url: ((args: any) => string) | string) {
  return (args?: object) =>
    axios({
      method,
      url: typeof url === 'function' ? url(args) : url,
      ...args,
    })
}

const fetchDefaultSerializer = (d) => d

export const onFetchData = async ({
  api,
  initialData = null,
  params,
  serializer = fetchDefaultSerializer,
}) => {
  try {
    const { data } = await api(params)

    return serializer(data)
  } catch (e) {
    console.error(e)

    return initialData
  }
}

const slugToLowerCase = (slug: string) => slug.toLowerCase();

export const API = {
  getSettings: request('get', '/dictionary/settings'),
  getCitySlugs: request('get', '/city/slugs'),
  getCityBySlug: request('get', ({ slug }) => `/city/by-slug/${slugToLowerCase(slug)}`),
  getTopicBySlug: request(
    'get',
    ({ topicSlug, citySlug }) => `/topic-public/by-slug/${slugToLowerCase(citySlug)}/${slugToLowerCase(topicSlug)}`
  ),
  getTours: request('get', '/tour'),
  getTourSlugs: request('get', '/tour/slugs'),
  getTourByBooking: request('get', ({ token, id }) => `/tour/by-booking/${id}/${token}`),
  getTourBySlug: request(
    'get',
    ({ citySlug, tourSlug }) => `/tour/by-slug/${slugToLowerCase(citySlug)}/${slugToLowerCase(tourSlug)}`
  ),
  getAgreementByType: request('get', (params) => `/agreement/by-type/${params.type}`),
  getTourReviewByTId: request('get', (params) => `/review/tour/${params.translationId}`),
  getCityReview: request('get', (params) => `/review/city/${params.cityId}/lang/${params.languageCode}`),
  getCityAverageReviews: request('get', (params) => `/review/city/${params.cityId}/averages`),
  getDeparturePublicGroupByDateByTId: request(
    'get',
    (params) => `/departure/public/group-by-date/${params.translationId}`,
  ),
  getDeparturePublicList: request('get', `/departure/public/list`),
  getAvailableLanguages: request('get', ({ cityId }) => `/departure/public/list-available-by-language/${cityId}`),
  getFAQ: request('get', '/faq'),
  createBookingPublic: request('post', `/booking/public`),
  getBookingPublic: request('get', ({ token, id }) => `/booking/public/${id}/${token}`),
  contactUs: request('post', `/traveller-message`),
  createReview: request('post', `/review`),
  cancelBooking: request('post', ({ id, token }) => `/booking/public/${id}/${token}/cancel`),
  updateBooking: request('patch', ({ id, token }) => `/booking/public/${id}/${token}`),
  leaveFeedback: request('post', 'no-show-reason'),
  confirmBooking: request('post', ({ id, token }) => `/booking/public/${id}/${token}/confirm`),
  confirmEmail: request('post', `auth/change-email-confirm`),
  getBookingPublicChatDetails: request('get', ({ id, token }) => `/booking/public/${id}/${token}/chat-details`),
}

export default API
